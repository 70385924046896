const flatListToHierarchical = (
  data: any = [],
  // This is just an optional options object to configure the keyname of the
  // children array, and what fields should be used as the id, and parent id relationship.
  // They will by default be "id", "parentId", and "children".
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree: Array<any> = [];
  const childrenOf: { [key: string]: any } = {};
  data.forEach((item: { [key: string]: string | number }) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    // Create an index in the childrenOf object with the item id as the key of an empty or existing array.
    // It is important for this function to use an existing array reference if it already exists, or it will break the code.
    childrenOf[id] = childrenOf[id] || [];
    // This is relying on object reference of childrenOf[id] to allow newItem to update its children even if it it has already ben bound to the tree.
    newItem[childrenKey] = childrenOf[id];
    // If the item has a parentId, then it must be a child. Add it to the parent's children list.
    // Otherwise it is a root item, and should be added to the tree array.
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : // Although it is added to the tree before its menu items are set, the object reference is saved so that whatever childrenOf[$parentId] = tree.{id: $parentId}
        tree.push(newItem);
  });
  return tree;
};

export default flatListToHierarchical;
