import * as React from "react";
import styled from "@emotion/styled";

// local import
import { screenSize, colors } from "../../utils/css/themes";
import SearchIcon from "../../utils/images/Nav/icon-mag.inline.svg";
import { useState } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
    flex-direction: column;
  }
`;
const LabelContainer = styled.div`
  display: flex;
  width: 0;
`;

const Label = styled.label`
  visibility: hidden;
  width: 0;
`;
const InputContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
const Input = styled.input`
  font-family: Arial;
  width: 100%;
  padding: 0.75rem 0 0.75rem 0.5rem;
  font-size: 12px;
  padding-right: 25px;
  border-radius: 0.25rem;
  border: solid 1px #d7dae1;
  @media (max-width: ${screenSize.largeTablet}) {
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.14);
    font-size: 16px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
    }
  }
`;

const IconContainer = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  top: 11px;
  position: absolute;
  @media (max-width: ${screenSize.largeTablet}) {
    svg {
      g {
        fill: white;
      }
    }
  }
`;
const SearchHeader = () => {
  const [search, setSearch] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    search &&
      navigate("/search/", {
        state: { search },
      });
  };

  const isBrowser = () => typeof window !== "undefined";

  const getSearch = () => {
    const language = isBrowser() && window.localStorage.getItem("language");
    if (language) {
      if (language === "es_ES") {
        return "Emisión, sin fines de lucro, noticias, programa, evento";
      } else {
        return "Issue, non-profit, news, program, event";
      }
    } else {
      return "Issue, non-profit, news, program, event";
    }
  };

  return (
    <SearchContainer>
      <InputContainer onSubmit={e => handleSubmit(e)}>
        <Label htmlFor="search_submit">Search Submit</Label>
        <LabelContainer>
          <Label htmlFor="search">Search</Label>
        </LabelContainer>
        <Input
          placeholder={getSearch()}
          value={search}
          id="search"
          title="search"
          onChange={e => setSearch(e.target.value)}
        />

        <IconContainer
          title="search_submit"
          name="search_submit"
          type="submit"
          id="search_submit"
        >
          <SearchIcon></SearchIcon>
        </IconContainer>
      </InputContainer>
    </SearchContainer>
  );
};

export default SearchHeader;
