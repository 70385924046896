import * as React from "react";
import Facebook from "../../utils/images/Footer/fb.inline.svg";
import Instagram from "../../utils/images/Footer/ig.inline.svg";
import YouTube from "../../utils/images/Footer/yt.inline.svg";
import LinkedIn from "../../utils/images/Footer/li.inline.svg";
import styled from "@emotion/styled";

const SocialImages = () => {
  const SocialImage = styled.a`
    margin-right: 0.7rem;
  `;

  return (
    <>
      <SocialImage
        aria-label="Facebook"
        href="https://www.facebook.com/seekidsdream"
      >
        <Facebook />
      </SocialImage>
      <SocialImage
        aria-label="YouTube"
        href="https://www.youtube.com/user/SeeKidsDream"
      >
        <YouTube />
      </SocialImage>
      <SocialImage aria-label="Instagram" href="https://www.instagram.com">
        <Instagram />
      </SocialImage>
      <SocialImage aria-label="LinkedIn" href="https://www.linkedin.com/company/see-kids-dream/">
        <LinkedIn />
      </SocialImage>

    </>
  );
};

export default SocialImages;
