export const useUrlParse = (url: string | Link): string => {
  if (!url) return;
  if (typeof url == "object") url = (url as Link).link || "#";
  url = url
    ?.toString()
    .toLowerCase()
    .replace(/localhost:8000|https:\/\/seekidsdream.org/g, "");
  return url;
};

declare interface Link {
  id: string;
  link: string;
}
