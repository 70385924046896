/** @jsx jsx */
// 1st party framework
import * as React from "react";
import { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { jsx, css } from "@emotion/react";
import CloseNavIcon from "../../utils/images/Nav/icon-hamburger-close.inline.svg";

// local import
import Search from "./SearchHeader";
import MobileLinks from "./MobileLinks";
import SocialImages from "../Helpers/SocialImages";
import NavDropdown from "./NavDropdown";
import { screenSize, colors, quicksandFont } from "../../utils/css/themes";
import styled from "@emotion/styled";
import Carat from "../../utils/images/Nav/icon-carat.inline.svg";
import { useUrlParse } from "../../utils/useUrlParse";
import flatListToHierarchical from "../../utils/flatListToHierarchical";

const StyledLink = styled((props: any) => <Link {...props} />)`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 2rem 1.5vw;

  letter-spacing: 0.3px;
  text-decoration: none;
  color: ${colors.blue};
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
    justify-content: space-between;
    color: white;
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
    padding: 0.75rem 0;
    div {
      svg {
        path {
          stroke: white;
        }
      }
    }
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 2rem 1.5vw;

  letter-spacing: 0.3px;
  text-decoration: none;
  color: ${colors.blue};
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
    justify-content: space-between;
    color: white;
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
    padding: 0.75rem 0;
    div {
      svg {
        path {
          stroke: white;
        }
      }
    }
  }
`;

const NavLabel = styled.div`
  @media (max-width: ${screenSize.largeTablet}) {
    font-size: 30px;
    margin-left: 1.5rem;
  }
`;

const CloseNavButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const SocialContainer = styled.div`
  padding: 1rem 0;
`;

const DesktopOnly = styled.div`
  display: block;
  margin: auto;
  @media (max-width: ${screenSize.largeTablet}) {
    display: none;
  }
`;

const MobileNavOnly = styled.div`
  display: none;
  @media (max-width: ${screenSize.largeTablet}) {
    display: block;
  }
`;

const CloseNavText = styled.div`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
`;

// TODO christian need to fix rotation not occuring
const CaratContainer = styled.div(
  ({ i, selectedNav }: any) => `
  transform: ${i == selectedNav ? "rotate(180deg)" : "none"};
  margin-left: 0.25rem;
  display: flex;
  svg {
    display: block;
  }
  @media (max-width: ${screenSize.largeTablet}) {
    svg {
      height: 20px;
      width: 20px;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  }`
);

const NavListContainer = styled.div`
  margin-right: 1rem;
  @media (max-width: ${screenSize.largeDisplay}) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const HighlightedNav = styled.span(
  ({ i, selectedNav, slug, label }: any) => `
          visibility: ${
            i == selectedNav ||
            slug.includes(label.replace(" ", "-").toLowerCase())
              ? "initial"
              : "hidden"
          };
  width: 100%;
  display: block;
  height: 11px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.blue};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  @media (max-width: ${screenSize.largeTablet}) {
    display: none;
  }
`
);

const Navigation = (props: NavigationProps) => {
  const [selectedNav, setSelectedNav] = useState(null); // Only used when use clicks a dropdown link, in mobile menu, or desktop menu.
  const NavContainer = styled.div`
    display: inline-block;
    position: relative;
    list-style-type: none;
    width: auto;
    @media (max-width: ${screenSize.largeDisplay}) {
      margin: 0 0rem;
    }

    @media (max-width: ${screenSize.largeTablet}) {
      display: block;
    }
    // &:hover {
    //   @media (min-width: ${screenSize.largeTablet}) {
    //     div {
    //       ${!selectedNav && "display: block"};
    //     }
    //     span {
    //       visibility: initial;
    //     }
    //   }
    // }
  `;
  const Nav = styled.div`
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    @media (max-width: ${screenSize.largeTablet}) {
      padding: 0 1rem;
      display: block;
      visibility: ${props.showNav ? "visible" : "hidden"};
      /// only ease when the nav moves out
      transition: ${props.showNav
          ? "visibility 0s 0s ease"
          : "visibility 0s .75s ease"},
        transform 0.75s;
      position: fixed;
      z-index: 2;
      overflow-y: auto;
      height: 100%;
      top: 0;
      right: 0;
      width: 100%;
      will-change: transform;
      transform: ${props.showNav ? "translateX(0)" : "translateX(480px)"};
      max-width: ${screenSize.largePhone};
      background-color: ${colors.blue};
      @media (max-width: 480px) {
        transform: ${props.showNav ? "translateX(0)" : "translateX(100vw)"};
      }
    }
  `;

  const DropDownNavOnly = styled.span`
    display: none;
    @media (max-width: ${screenSize.largeTablet}) {
      display: block;
    }
  `;

  const DropdownNavTopContainer = styled.div`
    margin: 1rem 0;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  `;

  const Donate = css`
    font-family: ${quicksandFont.bold};
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: 100%;
    background-color: ${colors.green};
    color: white;
    padding: 0.75rem 2.5rem;
    letter-spacing: 1px;
    justify-content: flex-end;
    border-radius: 1.5rem;
    font-weight: bold;
    font-size: 0.75rem;
    font-weight: bold;
    margin: auto 0 auto 1rem;
    border: 1px solid ${colors.green};

    @media (max-width: ${screenSize.largeDisplay}) {
      margin-left: 1rem;
    }
    @media (max-width: ${screenSize.largeTablet}) {
      background-color: white;
      padding: 0.25rem 1.5rem;
      color: ${colors.blue};
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
      background-color: white;
      color: ${colors.green};
    }
  `;

  const { nodes: allMainMenuItems } = useStaticQuery(graphql`
    query getMainMenu {
      allWpMenu(filter: { name: { eq: "MainMenu" } }) {
        edges {
          node {
            name
            menuItems {
              nodes {
                id
                parentId
                label
                url
              }
            }
          }
        }
      }
    }
  `).allWpMenu.edges[0].node.menuItems;
  const structuredMainMenu = flatListToHierarchical(allMainMenuItems);

  const setDropdown = (i: number) => {
    i !== selectedNav ? setSelectedNav(i) : setSelectedNav(null);
  };

  const handleCloseDropdown = () => {
    selectedNav != null && setSelectedNav(null);
  };

  // on click, close out the dropdown
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleCloseDropdown);
  //   return () => {
  //     document.removeEventListener("mousedown", handleCloseDropdown);
  //   };
  // });

  const buildNavItemNoChildren = (navItem: any, i: number) => {
    return (
      <StyledLink to={navItem.url}>
        <NavLabel>{navItem.label}</NavLabel>
      </StyledLink>
    );
  };

  const buildNavItemWithChildren = (navItem: any, i: number) => {
    return (
      <React.Fragment>
        <NavButton onClick={() => setDropdown(i)}>
          <NavLabel>{navItem.label}</NavLabel>
          <CaratContainer i={i} selectedNav={selectedNav}>
            <Carat />
          </CaratContainer>
        </NavButton>
        <NavDropdown
          key={i}
          label={navItem.label}
          url={navItem.url}
          items={navItem.children}
          i={i}
          selectedNav={selectedNav}
        />
      </React.Fragment>
    );
  };

  return (
    <Nav>
      <DropDownNavOnly>
        <DropdownNavTopContainer>
          <MobileNavOnly>
            <Link css={Donate} to="/donate">
              {"Donate"}
            </Link>
          </MobileNavOnly>
          <CloseNavButton onClick={() => props.setShowNav()}>
            <CloseNavIcon></CloseNavIcon>
            <CloseNavText>Close</CloseNavText>
          </CloseNavButton>
        </DropdownNavTopContainer>
        <Search />
      </DropDownNavOnly>
      <NavListContainer>
        {structuredMainMenu.map((navItem: navItem, i: number) => {
          return (
            <NavContainer key={i}>
              {navItem.children.length == 0
                ? buildNavItemNoChildren(navItem, i)
                : buildNavItemWithChildren(navItem, i)}
              <HighlightedNav
                i={i}
                selectedNav={selectedNav}
                slug={props.slug}
                label={navItem.label}
              />
            </NavContainer>
          );
        })}
      </NavListContainer>
      <DesktopOnly>
        <Link css={Donate} to="/donate">
          {"Donate"}
        </Link>
      </DesktopOnly>
      <MobileNavOnly>
        <MobileLinks></MobileLinks>
        <SocialContainer>
          <SocialImages></SocialImages>
        </SocialContainer>
      </MobileNavOnly>
    </Nav>
  );
};

interface NavigationProps {
  showNav: boolean;
  setShowNav: any;
  headerHeight: -1;
  slug: string;
}
interface navItem {
  children: any;
  label: string;
  url: string;
}

export default Navigation;
