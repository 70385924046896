/** @jsx jsx */
import * as React from "react";
import { jsx, css } from "@emotion/react";
import { colors } from "../../utils/css/themes";
import { Link } from "gatsby";

const BackStyle = css`
  padding: 0.5rem 1rem;
  border-radius: 40px;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
  letter-spacing: 0.5px;
  color: ${colors.blue};
  text-decoration: none;
  border: solid 1px ${colors.orange};
  background-color: white;
  width: fit-content;
  margin-bottom: 1.5rem;
  &:hover {
    background-color: ${colors.blue};
    color: white;
    border: solid 1px ${colors.blue};
  }
`;

const BackButton = (props: GoBackProps) => {
  return (
    <Link css={BackStyle} to={props.to}>
      &larr; {props.label}
    </Link>
  );
};

interface GoBackProps {
  to: string;
  label: string;
}

export default BackButton;
