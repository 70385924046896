/** @jsx jsx */
// 1st party imports
import * as React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { colors } from "../../utils/css/themes";

// local imports
import { screenSize } from "../../utils/css/themes";
import { jsx } from "@emotion/react";
import { useUrlParse } from "../../utils/useUrlParse";

const DropdownWrapper = styled.div(
  ({ i, selectedNav }: any) => `
  display: ${i === selectedNav ? "block" : "none"};
  position: absolute;
  top: 82px;
  left: 0px;
  z-index: 100;
  @media (max-width: ${screenSize.largeTablet}) {
    position: relative;
    top: -4px;
  }
`
);
const Dropdown = styled.div`
  width: calc(100% + 4rem);
  transform: translateY(4px);
  ox-sizing: border-box;
  margin: 0 1rem 0 -2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 4;
  background-color: white;
  a {
    border-bottom: solid 1px ${colors.lightGrey};
    &:last-child {
      border-bottom: 0;
    }
  }
  @media (max-width: ${screenSize.largeTablet}) {
    position: relative;
    top: 0;
    margin: 0;
    width: 100%;
    background-color: rgba(0, 64, 104, 0.99);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    a {
      border: none;
    }
  }
`;

const DropdownLink = styled((props: any) => <Link {...props} />)`
  display: block;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color: ${colors.blue};
  font-weight: bold;
  &:focus,
  &:hover {
    color: white;
    background-color: ${colors.blue};
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  @media (max-width: ${screenSize.largeTablet}) {
    color: white;
    padding: 0.7rem 1.5rem;
    font-weight: normal;
  }
`;

const NavDropdown = (props: NavDropdownProps) => {
  return (
    <DropdownWrapper i={props.i} selectedNav={props.selectedNav}>
      <Dropdown>
        {props.items.map((subItem: subItem, i: number) => {
          const url =
            subItem.children.length > 0 ? subItem.children[0].url : subItem.url;
          return (
            <DropdownLink key={subItem.label} to={url}>
              {subItem.label}
            </DropdownLink>
          );
        })}
      </Dropdown>
    </DropdownWrapper>
  );
};

interface NavDropdownProps {
  items: Array<any>;
  label: string;
  url: string;
  selectedNav: -1;
  i: number;
}

interface subItem {
  children: Array<subItem>;
  items: Array<any>;
  label: string;
  url: string;
}

export default NavDropdown;
