/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TragicMarkerTTF from "./TragicMarker.ttf";
import TragicMarkerWOFF from "./TragicMarker.woff";
import TragicMarkerEOT from "./TragicMarker.eot";
import { colors, screenSize, quicksandFont } from "./themes";

export const globalStyles = css`
  .has-text-align-center {
    text-align: center;
  }
  html,
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    height: 100%;
    width: 100%;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
  }

  * {
    box-sizing: inherit;
  }
  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }
  @font-face {
    font-family: "TragicMarker";
    font-display: swap;
    src: url(${TragicMarkerEOT}); /* IE 9 - 11 */
    src: local("Tragic Marker"), local("Tragic-Marker"),
      url(${TragicMarkerTTF}) format("ttf"),
      url(${TragicMarkerWOFF}) format("woff");
  }
  h1 > div {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-display: swap;
    font-family: "Baloo", -apple-system, BlinkMacSystemFort, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 3.25rem;
    line-height: 1.1;
    color: ${colors.blue};
  }
  h2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-size: 1.25rem;
    font-display: swap;
    font-family: "Baloo", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 1.62671rem;
    line-height: 1.1;
    color: ${colors.blue};
  }
  h3 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "Baloo", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    font-weight: bold;
    font-size: 1rem;

    text-rendering: optimizeLegibility;
    font-size: 1.38316rem;
    line-height: 1.1;
    color: ${colors.blue};
  }
  h4 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    text-rendering: optimizeLegibility;
    font-size: 1.25rem;
    line-height: 1.1;
    color: ${colors.blue};
  }
  h5 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "Baloo", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    color: ${colors.blue};

    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 0.85028rem;
    line-height: 1.1;
  }
  h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "Baloo", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 0.78405rem;
    line-height: 1.1;
    color: ${colors.blue};
  }

  a,
  button {
    &:hover {
      cursor: pointer;
    }
  }
  div,
  button,
  a,
  p {
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;;
  }

  li,
  p {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    color: #212121;
  }
  p {
    min-height: 1rem;
  }
  p.blockquote {
    color: ${colors.blue};
    font-family: ${quicksandFont.medium};
    font-size: 1.25rem;
    font-style: italic;
    position: static;
    z-index: 1;
    text-align: center;
    width: 70%;
    margin: 10px auto;
  }
  p.blockquote:before {
    content: open-quote;
    font-family: baloo;
    font-size: 3em;
    line-height: 0.8em;
    position: absolute;
    margin-left: -0.75em;
    z-index: -1;
  }
  p.blockquote:after {
    content: close-quote;
    font-family: baloo;
    font-size: 3em;
    line-height: 0.8em;
    position: absolute;
    margin-left: 0.15em;
    z-index: -1;
  }
  hr {
    min-height: 0;
    border-top: 1px solid ${colors.lightGrey};
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 480px) {
    html {
      font-size: 100%;
    }
  }
  figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  .wp-block-image {
    display: inline;
    width: fit-content;
    figure {
      flex-direction: column;

      &.aligncenter {
        align-items: center;
      }
    }
    &.no-white-background {
        figure {
          box-shadow: none;
        }
      }
    }
    img {
      max-width: 100%;
      display: block;
      height: 100%;
      width: auto;
      margin: auto;
    }
  }
  .wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    .blocks-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      width: 100%;
      .blocks-gallery-item {margin:auto;
      }
    }
    @media only screen and (max-width: ${screenSize.smallTablet}) {
      flex-direction: column;
    }
    .blocks-gallery-item {
      margin:auto;
    }

    &.columns-3 {
      li {
        flex-grow: 1;
        width: 33%;
        @media only screen and (max-width: ${screenSize.smallTablet}) {
          width: 100%;
        }
      }
    }
    &.columns-2 {
      li {
        flex-grow: 1;
        width: 50%;
      }
    }
    &.columns-1 {
      li {
        flex-grow: 1;
        width: 100%;
      }
    }
    li {
      list-style: none;
    }
  }
  figure {
    width: fit-content;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 1rem 0;
    figcaption {
      font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
      font-size: 11px;
      font-weight: bold;
      color: ${colors.blue};
      margin: auto;
      padding: 1rem 0 0 0;
    }
    img {
      max-width: 100%;
      padding: 1rem;
    }
  }
  iframe {
    display: flex;
    margin: auto;
    padding: 1rem;
  }
`;
