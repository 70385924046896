export type ColorOptions = ["blue", "yellow", "green", "orange", "lightGrey"];

export const colors = {
  blue: "#005284",
  yellow: "#ffd500",
  green: "#74b443",
  orange: "#ec8218",
  grey: "#696969",
  lightGrey: "#d7dae1",
  darkGrey: "#212121",
  blueGrey: "#ebedf3",
  purple: "purple",
};

export const screenSize = {
  smallPhone: "320px",
  largePhone: "480px",
  smallTablet: "768px",
  largeTablet: "1024px",
  largeDisplay: "1200px",
};

export const quicksandFont = {
  regular: "'Quicksand', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, \
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  medium:  "'Quicksand-Medium', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, \
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  bold:  "'Quicksand-Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, \
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  light: "'Quicksand-Light_', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, \
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"
};