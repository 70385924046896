import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";

const MobileLinks = () => {
  const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  `;

  const Link = styled.a`
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: white;
  `;

  const links = useStaticQuery(graphql`
    query {
      allWpMenuItem(filter: {menu: {node: {name: {eq: "Footer"}}}}) {
        nodes {
          label
          path
          id
        }
      }
    }
  `).allWpMenuItem.nodes;

  return (
    <LinkContainer>
      {links.map((link: any, i: number) => (
        <Link href={"/" + link.object_slug} key={i}>
          {link.title}
        </Link>
      ))}
    </LinkContainer>
  );
};

export default MobileLinks;
