/** @jsx jsx */
import * as React from "react";
import { useState } from "react";
import { Global, css, jsx } from "@emotion/react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styled from "@emotion/styled";
import { screenSize, colors, quicksandFont } from "../utils/css/themes";
import { globalStyles } from "../utils/css/global";
import "../utils/css/style.css";
import BackButton from "./Helpers/BackButton";
import DangerousHtml from "./Helpers/DangerousHtml";

const PageContainer = styled.div`
  display: flex;
  margin: 7vw auto 0 auto;
  flex-direction: column;
  padding: 0 1rem 7rem 1rem;
  max-width: 1160px;
  position: relative;
  @media (max-width: ${screenSize.largePhone}) {
    flex-direction: column;
    margin: 1em auto;
  }
`;

const TitleText = styled.h1`
  text-align: center;
  color: ${colors.blue};
  font-size: 50px;
  order: 1;
  width: 75%;
  margin: auto;
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
  }
`;
const BackButtonContainer = styled.div`
  order: 2;
  padding-bottom: 2rem;
  position: absolute;
  top: 2rem;
  @media (max-width: ${screenSize.largeDisplay}) {
    order: 0;
    top: 0;
    margin: auto;
    position: relative;
  }
`;

export const WordPressChildrenContent = styled.div`
  font-family: ${quicksandFont.regular};
  font-size: 21px;
  font-weight: 500;
  line-height: 1.52;
  order: 3;
  margin-top: 1rem;
  @media (max-width: ${screenSize.largePhone}) {
    padding: 0 1rem;
  }
`;

const BackGroundWhite = styled.div`
  background-color: white;
`;

const SiteContainer = styled.article`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
  height: 0;

  //ie11 fix for footer layout
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const Main = styled.main`
  margin: 0 auto;
  padding: 0px;
  flex-grow: 1;
  width: 100%;
`;

const Layout = (props: LayoutProps) => {
  const [headerHeight, setHeaderHeight] = useState(null);
  const [showNav, setShowNav] = useState(false);

  return (
    <SiteContainer>
      <Global styles={globalStyles} />
      <Header
        showNav={showNav}
        setShowNav={(e: any) => setShowNav(e)}
        headerHeight={headerHeight}
        setHeaderHeight={(e: number) => setHeaderHeight(e)}
      />
      <Main id="main">
        {props.mainPage ? (
          <BackGroundWhite>{props.children}</BackGroundWhite>
        ) : (
          <PageContainer>
            <BackButtonContainer>
              {props.backButton && (
                <BackButton
                  to={props.backButton.link}
                  label={props.backButton.label}
                />
              )}
            </BackButtonContainer>
            {props.title && (
              <TitleText>
                <DangerousHtml>{props.title}</DangerousHtml>
              </TitleText>
            )}
            <WordPressChildrenContent>
              {props.wordPressContent && (
                <DangerousHtml>{props.wordPressContent}</DangerousHtml>
              )}
              {props.children}
            </WordPressChildrenContent>
          </PageContainer>
        )}
      </Main>
      <Footer />
    </SiteContainer>
  );
};

interface LayoutProps {
  children?: any;
  title?: string;
  wordPressContent?: string;
  backButton?: backButton;
  mainPage?: boolean;
  postNav?: any;
}

interface backButton {
  label: string;
  link: string;
}

export default Layout;
